import React from "react"
import DefaultLayout from "../layouts/default"

const DeleteAccount = () => (
  <DefaultLayout>
    <h1>Itineo</h1>
    <h2>Delete Your Traveller Account</h2>
    <p>If you would like to request that your traveller account and associated data be deleted, please follow the steps below:</p>
    <ol>
      <li>Download the Itineo app, available for Android and iOS.</li>
      <li>Log in using your phone number (you will receive a OTP code via SMS to authenticate).</li>
      <li>Open the left navigation drawer, click on "Profile/Perfil" and then click on "Delete account/Excluir conta".</li>
    </ol>
    <h2>Data Deletion and Retention</h2>
    <p>When you request the deletion of your account, the following data will be deleted:</p>
    <ul>
      <li>Full name</li>
      <li>Email address</li>
      <li>Date of birth</li>
      <li>Identification document number (e.g., CPF in Brazil)</li>
      <li>Profile photo</li>
      <li>Any additional photos or PDF files (and text descriptions of them) that you voluntarily uploaded via the app</li>
    </ul>
    <p>The following data will not be deleted:</p>
    <ul>
      <li>Data shown to you via the app but not submitted by you, which was provided directly from/to your travel agency</li>
    </ul>
    <p>To have this data deleted, you will need to contact your travel agency directly.</p>
    <p>If you have any questions, please contact our support team at <a href="mailto:erica@itineo.com.br">erica@itineo.com.br</a>.</p>
    <h2>Registry Information</h2>
    <p><strong>Dun & Bradstreet D-U-N-S number:</strong> 920873666</p>
    <p><strong>Organization name:</strong> ITINEO INTERMEDIACAO DE SERVICOS TURISTICOS LTDA</p>
    <p><strong>Organization address:</strong> Rua CEARA 1425, Lajeado, ALTO DO PARQUE - 95913-274, Brazil (BR)</p>
  </DefaultLayout>
)

export default DeleteAccount
